import './Home.css'
import React from 'react'
import linkedinIcon from '../../icons/linkedin.png'

export const SectionIntro = () => {
  return (
    <div className='SectionIntro snap'>
      <div className='SectionIntroSkillInfo'>
        <h1>
          Android development
        </h1>
        <h3>
          Kotlin | Jetpack Compose | Coroutines
        </h3>
        <h5 className='SectionIntroSkillInfoSocial'>
          <a href='https://www.linkedin.com/in/mateusz-k/' target="_blank" rel='noopener noreferrer'>
            <img alt='LinkedIn' src={linkedinIcon} className='SectionIntroSkillInfoSocialIcon' />
          </a>
          <a href='mailto:matkordula@gmail.com' target="_blank" rel='noopener noreferrer'>
            matkordula@gmail.com
          </a>
        </h5>
        <a href='https://play.google.com/store/apps/dev?id=5065490243063352704&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" rel='noopener noreferrer'>
          <img className='SectionIntroSkillInfoGooglePlay' alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
        </a>
      </div>
      <div className='SectionIntroCompanyInfo'>
      </div>
    </div>
  )
}
